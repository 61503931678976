body {
    background-color: #121417;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.header {
    padding: 10px;
    /* margin: 10px; */
    text-align: left;
    border-bottom: 1px solid white;
}

.header img {
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
}

.contentHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin: 40px auto 0;
    max-width: 400px;
} 

.btnhome {
    border-radius: 7px;
    color: #FFFFFF;
    height: 50px;
    width: 100%;
    border: none;
    margin-top: 10px;
    transition: transform 1s;
}

.btnhome:hover{
    transform: translateY(-3px);
}
.signin {
    background-color: #1A80E5;
}

.login {
    margin-top: 0;
    background-color: #293038;
}

.checking {
    color: #9EABB8;
    margin-top: 20px;
}

p {
    font-size: 15px;
}