.split {
    display: flex;
    color: #FFFFFF;
}

.dashboard {
    min-height: 100vh;
    width: 150px;
    text-align: left;
    margin: 10px;
    border-right: 1px solid white;
    transition: width 0.3s; /* Smooth transition */
}

.dashboard.minimized {
    width: 50px; /* Width when minimized */
}

.dashboard .btnPrb img {
    width: 20px;
    height: 20px;
    background-color: transparent;
    padding-right: 5px;
}

.dashboard.minimized .btnPrb img {
    padding-right: 0; /* Remove padding when minimized */
}

.dashboard .btnPrb {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.dashboard.minimized .btnPrb {
    justify-content: center; /* Center the button when minimized */
}

.dashboard .btnPrb span {
    display: inline; /* Default display for text */
}

.dashboard.minimized .btnPrb span {
    display: none; /* Hide text when minimized */
}

.btnPrb {
    border-radius: 7px;
    color: #FFFFFF;
    height: 50px;
    width: 100%;
    border: none;
    cursor: pointer;
}

.prbRightSide {
    width: 100%;
    margin-right: 10px;
}
.search {
    display: flex;
    width: 100%;
    height: 50px;
    color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
}

.inpPrb {
    background-color: #1C2126;
    border-radius: 10px;
    height: 40px;
    width: 200px;
    border: none;
    color: white;
    text-align: center;
    margin-right: 10px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    width: auto;
}

.PrbTagsBtn {
    border-radius: 7px;
    color: #FFFFFF;
    height: 50px;
    width: 100px;
    margin: 4px;
    background-color: #1C2126;
    border: none;
}

.list table {
    width: 100%; 
    border-collapse: collapse; /* Merge border of adjacent cells */
}

.list th, .list td {
    border: 1px solid black; 
    padding: 10px; /* Add padding to table cells */
    text-align: center; 
}

.prbTitle{
    text-align: left;
}

.list th {
    background-color: #1C2126;
}
